

.header-container-control {
    min-height: 60px;
    width: 100%;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
}
.navbar-collapse .collapse .show{
    background-color: var(--dark);
}

.container-fluid-custom-no-ab{
    z-index: 1;
    position: relative;
    background-color: var(--textLight);
    padding-left: 0% !important;
    padding-right: 0% !important;
}
.container-fluid-custom{
    z-index: 1;
    position: relative;
    background-color: var(--dark);
    padding-left: 0% !important;
    padding-right: 0% !important;
}

.container-fluid-custom-open{
    transition-duration: 0.2s;
    background-color: var(--dark);
}

.navbar-collapse{
    transition-duration: 0.2s;
}

.header-container-control-active {
    position: relative;
    width: 100%;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;

}

.header-logo-control {
    height: 40px;
    width: auto;
    object-fit: contain;
}

.navbar-dark .navbar-toggler {
    border: none !important;
    color: var(--textLight) !important;
    margin-left: auto !important;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.text-menu {
    font-size: 16px;
    color: var(--dark) !important;
}
.navbar-collapse {
    width: 100% !important;
}
.navbar-nav {
    margin-left: auto !important;
}
.nav-link {
    margin-left: 16px !important;
    margin-right: 16px !important;
    color: var(--textLight) !important;
    font-family: "Product-Sans-Regular";
    font-weight: 700;
}
.nav-link:active {
    color: var(--red) !important;
}

.nav-link:hover {
    margin-left: 16px !important;
    margin-right: 16px !important;
    color: var(--red) !important;
    font-family: "Product-Sans-Regular";
    font-weight: 700;
}

.grid-control {
    margin-top: 16px;
    margin-bottom: 16px;
}

.logout-img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 4px;
}

.scoutout-logo {
    width: 200px;
    height: auto;
    object-fit: contain;
}
@media only screen and (max-width: 1024px) {
    .header-logo-control {
        height: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .header-logo-control {
        height: 40px;
    }
  
   
}
