

.footer-control {
    background-color: var(--dark);
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 10%;
    padding-right: 10%;
}

.footer-text-title{
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    font-family: "Product-Sans-Bold";
    color: var(--yellow);
    line-height: 1.2em;
    text-shadow: 2px 3px #000;
}

.footer-text-title:hover{
   color: var(--red) !important;
}

.footer-text-li{
    margin-top: 32px;
    text-align: center;
    border-right-style: solid;
    border-right-color: var(--yellow);
}

.footer-text-li-last{
    margin-top: 32px;
    text-align: center;
    border-right-style: none;
    border-right-color: var(--yellow);
}

.logo {
    width: 300px;
    height: 100px;
    object-fit: contain;
}

.contact-control {
    justify-content: end !important;
    margin-left: auto !important;
}

.follow-text {
    font-size: 40px;
    margin-left: auto;
    margin-bottom: 40px;
    font-weight: bold;
    font-family: "Product-Sans-Bold";
    color: var(--bgMain);
    line-height: 1.2em;
    text-shadow: 2px 3px #000;
}

.contact-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-left: 16px;
    margin-right: 16px;
    object-fit: contain;

}

@media screen and (max-width: 1024px) {

}


@media screen and (max-width: 768px) {
    .footer-control {
        background-color: var(--dark);
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 5% !important;
        padding-right: 5% !important;
    }

    .logo {
        width: 300px;
        height: 100px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .contact-control {
        justify-content: center !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .contact-item {
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-left: 16px;
        margin-right: 16px;
        object-fit: contain;
    }

    .follow-text {
        font-size: 30px;
        margin-left: auto !important;
        margin-right: auto;
        margin-bottom: 40px;
        text-align: center;
        font-weight: bold;
        font-family: "Product-Sans-Bold";
        color: var(--bgMain);
        line-height: 1.2em;
    }
}
