@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.items-control {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: var(--dark);


    .items-section-0-control {
        width: 100% !important;
        border-bottom: solid 2px var(--darkgrey);
        padding-bottom: 16px;
    }

    .items-filter-control {
        width: 100%;
        margin-left: auto !important;

        .items-dropdown-control {
            width: 80%;
            margin-left: auto;
            margin-right: auto;


            .items-dropdown-format {
                margin-left: auto;
                margin-right: auto;
                background-color: transparent !important;
                font-size: 18px;
                color: white;
                border: solid 1px var(--darkgrey) !important;
                border-radius: 6px !important;
                font-weight: 400;
                font-family: 'Product-Sans-Regular';
            }
        }


        .items-button-filter {
            background-color: var(--yellow);
            width: 80%;
            border: none;
            margin-left: auto;
            margin-right: auto;
            border-radius: 6px;
            padding: 8px 8px;
            font-size: 18px;
            color: var(--textLight);
            font-weight: 400;
            font-family: 'Product-Sans-Regular';
            text-align: center;
        }
    }

    .items-grid-control {
        margin-top: 40px;

        .items-nft-img-hover-zoom {
            height: 300px;
            /* [1.1] Set it as per your need */
            overflow: hidden;
            /* [1.2] Hide the overflowing of child elements */


            .items-nft-img {
                width: 100%;
                height: auto;
                object-fit: contain;
                border-radius: 4px;

            }

        }

        /* [2] Transition property for smooth transformation of images */
        .items-nft-img-hover-zoom img {
            transition: transform .5s ease;
        }

        /* [3] Finally, transforming the image when container gets hovered */
        .items-nft-img-hover-zoom:hover img {
            transform: scale(1.2);
        }

        .item-nft-info-control {
            cursor: pointer;
            width: 90%;
            margin-top: 8px;
            margin-bottom: 8px;

            @include media-breakpoint-down(md){
                margin-left: auto;
                margin-right: auto;
            }
        }

        .items-nft-name {
            margin-top: 8px;
            font-size: 14px;
            color: var(--textLight);
            font-weight: 600;
            font-family: 'Product-Sans-Regular';
        }

        .items-nft-price {
            margin-top: 8px;
            font-size: 14px;
            margin-left: auto;
            color: var(--yellow);
            font-weight: 600;
            font-family: 'Product-Sans-Bold';
        }
    }
}