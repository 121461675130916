$theme-colors: (
    "info": tomato,
    "danger": teal
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
:root {
  --bgMain: #F7AF31;
  --yellow: #F7AF31;
  --brown: #6C4B51;
  --red: #EF4123;
  --dark: #211D3E;
  --blue: #A6EAFF;
  --textLight: #F9F5EB; 
  --black: #212131;
  --darkgrey: #888888;
}

@font-face {
  font-family: "Product-Sans-Regular";
  src: local("Product-Sans-Regular"), url(./fonts/Product-Sans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Product-Sans-Bold";
  src: local("Product-Sans-Bold"), url(./fonts/Product-Sans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Noto-Sans-Regular";
  src: local("NotoSans-Regular"), url(./fonts/NotoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Noto-Sans-Bold";
  src: local("NotoSans-Bold"), url(./fonts/NotoSans-Bold.ttf) format("truetype");
}


body {
  width: 100%;
  margin: 0;
  font-family: "Product-Sans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-yellow{
  color: var(--yellow) !important;
}

.text-light{
  color: var(--textLight) !important;
}

a{
  color: var(--red);
  text-decoration: none;
}

.padding-container{
  padding-left: 0% !important;
  padding-right: 0% !important;
}

@media screen and (max-width: 768px) {
  .padding-container{
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  
}
@media screen and (max-width: 576px) {

  .padding-container{
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  
}

