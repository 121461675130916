@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.section-0-control {
    background: url(../../assets/images/bg-main.jpg);
    background-size: cover;
    background-position-x: center;
    position: relative;
    background-repeat: repeat-y;
    width: 100%;
    min-height: 1300px !important;
    padding-top: 150px !important;
    background-color: var(--dark);
    margin-left: auto;
    margin-right: auto;

    .text-control{
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .title-header {

        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        font-style: normal;
        font-weight: 600;
        z-index: 1;
        font-size: 82px;
        line-height: 1.6em;
        letter-spacing: 5px;
        text-align: center;
        color: #f9f5eb;
        text-shadow: 4px 6px 0px #5e424e;

        @include media-breakpoint-down(md){
            font-size: 40px;
        }
    }

    @include media-breakpoint-down(md){
       min-height: 700px !important;
       padding-top: 0px !important;
    }
}

.section-1-control {
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;


    .filter-control {
        border-right-style: solid;
        border-right-color: var(--darkgrey);
        border-right-width: 2px;
    }

    .grid-nft-control {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .item-nft-control {
        position: relative;
        border-color: var(--darkgrey);
        border-radius: 8px;
        border-style: solid;
        margin-top: 16px;
        margin-bottom: 16px;
        border-width: 2px;
        width: 70%;
        margin-left: auto !important;
        margin-right: auto !important;
        opacity: 0.4;


        .img-nft {
            opacity: 0.4;
            width: 100%;
            height: auto;
            object-fit: contain;


        }

        .img-cover {
            width: 100% !important;
            height: 100%;
            position: absolute;
            background: linear-gradient(to left, transparent 20%, var(--darkgrey) 40%, var(--darkgrey) 60%, transparent 80%);
            background-size: 200% auto;
            opacity: 0.5;
            animation: shine 1s linear infinite;
        }

        @keyframes shine {
            to {
                background-position: 200% center;
            }
        }
    }

    .monday-text-gradient {
        text-align: center;



    }

}