@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
.collection-control {
    padding-top: 120px;
    background-color: var(--textLight);


    .collection-name-header {
        font-size: 60px;
        color: var(--dark);
        font-weight: bold;
        font-family: 'Product-Sans-Bold';
        text-align: center;

        @include media-breakpoint-down(md){
            font-size: 45px;
        }
    }

    .collection-info-control {
        margin-top: 80px;


        .collection-title-info {
            font-size: 24px;
            color: var(--darkgrey);
            font-weight: 400;
            font-family: 'Product-Sans-Regular';
            text-align: center;

            @include media-breakpoint-down(md){
                font-size: 20px;
            }
        }

        .collection-value-info {
            font-size: 30px;
            color: var(--dark);
            font-weight: 600;
            font-family: 'Product-Sans-Regular';
            text-align: center;
        }
    }

    .collection-desc-text{
        margin-top: 40px;
        margin-bottom: 40px;
        font-size: 22px;
        color: var(--dark);
        font-weight: 400;
        font-family: 'Product-Sans-Regular';
        line-height: 1.6em;
        text-align: center;

        @include media-breakpoint-down(md){
            font-size: 18px;
        }
    }

}