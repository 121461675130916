@import "~bootstrap/scss/functions"; 
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.item-detail-control {
    background-color: var(--dark);
    padding-top: 60px;
    padding-bottom: 60px;


    .item-grid-control {
        .item-detail-nft-img-hover-zoom {
            height: auto;
            /* [1.1] Set it as per your need */
            overflow: hidden;
            /* [1.2] Hide the overflowing of child elements */


            .item-detail-nft-img {
                width: 80%;
                height: auto;
                object-fit: contain;
                margin-left: auto;
                margin-right: auto;
            }
        }

        /* [2] Transition property for smooth transformation of images */
        .item-detail-nft-img-hover-zoom img {
            transition: transform .5s ease;
        }

        /* [3] Finally, transforming the image when container gets hovered */
        .item-detail-nft-img-hover-zoom:hover img {
            transform: scale(1.2);
        }


        .item-detail-info-control {

            .info-collection {
                font-size: 25px;
                color: white;
                text-decoration: underline;
                font-weight: 600;
                font-family: 'Product-Sans-Regular';
            }

            .info-header {
                font-size: 40px;
                color: white;
                font-weight: 600;
                font-family: 'Product-Sans-Bold';
            }
            .info-hashcode-control{
                margin-top: 16px !important;
                margin-bottom: 16px !important;
            }
            .info-hashcode {
              
                min-width: 120px;
                border-radius: 6px;
                background: #f4f4f4;
                padding: 8px 8px;
                font-size: 20px;
                color: var(--darkgrey);
                font-weight: 400;
                font-family: 'Product-Sans-Regular';
            }

            .info-preserve {
                font-size: 20px;
                color: var(--darkgrey);
                font-weight: 400;
                font-family: 'Product-Sans-Regular';
            }

            @include media-breakpoint-down(md){
                margin-top: 30px;
                padding-left: 24px;
                padding-right: 24px;
            }

        }
    }


}